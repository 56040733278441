import { isBrowser } from '../../utils'

const InspRedirectVideo = () => {
  return (
    isBrowser() &&
    window.location.replace('https://youtu.be/g4hHGjCXaxM?si=nkRyPYfSxBYy14r3')
  )
}

export default InspRedirectVideo
